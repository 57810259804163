<!--
  Patch for 'DOMNodeInserted' warning message (if any):
  
  The issue lies within the Quill editor itself
  It is not possible to fix the issue without updating Quill editor to v2
  But I am facing too many issues & errors when updating the editor

  So if the warning message is shown, (most likely when u first install the project)
  and there is not alternative editor to use,
  Kindly follow the instruction below:

  In node_modules > quill > dist > quill.js:
  
  Locate 'DOMNodeInserted' and replace:

  ``` _this.domNode.addEventListener('DOMNodeInserted', function () {}); ```

  with

  ``` var observer = new MutationObserver(function () {});
      observer.observe(_this.domNode, { childList: true }); ```
  
  The warning message should be gone.

  https://github.com/vueup/vue-quill/issues/409
  - - - - - - - - - - - - - - - - - - - - - - -

  Example for using this component:

  <div class="mt-4">
    <QuillEditor
      :loading="editorLoading" // actually not required
      :htmlContent="letter"
      @init-editor="initEditor"
      @update-editor="updateEditor"
    ></QuillEditor>
  </div>

  - - - - - - - - - - - - - - - - - - - - - - -

  At where this editor is called,
  add the following handler function

  initEditor({ editor, content }) {
    this.editor = editor;
    this.changedLetter = <original copy>;
  },
  updateEditor({ content }) {
    this.changedLetter = content;
  },

  add any handler for id field if necessary
 -->
<template>
  <div>
    <!-- start of Export modal -->
    <b-modal
      v-if="!disableExport"
      id="export-edit-legal-writer"
      size="sm"
      centered hide-footer hide-header
    >
      <h5 class="text-blue font-weight-bold mb-3">Export To</h5>
      <div
        v-for="(option, index) in export_options"
        :key="'export_pdf' + index"
        class="row mt-2"
      >
        <div class="col-2 pr-0">
          <input
            :name="'pdf' + index"
            :id="'pdf' + index"
            type="checkbox"
            :value="option"
            v-model="chosenExports"
            class="form-check-input mx-auto"
          />
        </div>

        <div class="col-2 pl-0 pr-0">
          <span class="material-icons-outlined text-blue justify-content-center">
            {{ option.icon }}
          </span>
        </div>

        <div class="col pl-0">
          <b class="text-blue">
            {{ option.name }}
          </b>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <button
            class="btn btn-primary float-right"
            @click="exportContent"
          >
            Export
          </button>
        </div>
      </div>
    </b-modal>
    <!-- end of Export modal -->

    <div :class="{'card shadow rounded p-3 mt-4': !disableCard}">
      <!-- start of Toolbar -->
      <div v-show="!editorLoading" id="editor-toolbar" class="toolbar">
        <span style="margin-right: 0px;" class="ql-formats">
          <!-- Font Size -->
          <span class="ql-formats">
            <select class="ql-size">
              <option value="10px">Small</option>
              <option selected>Normal</option>
              <option value="18px">Large</option>
              <option value="32px">Huge</option>
            </select>
          </span>

          <!-- Text Decoration -->
          <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
          </span>

          <!-- Bulleting & Indentation -->
          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-indent" value="-1"></button>
            <button class="ql-indent" value="+1"></button>
          </span>

          <!-- Text Color & Highlight -->
          <span class="ql-formats">
            <select class="ql-color">
              <option
                v-for="(color, index) in editor_color"
                :key="index"
                :value="color"
              >{{ color }}</option>
            </select>

            <select class="ql-background">
              <option
                v-for="(color, index) in [
                  'black', 'white', 'grey',
                  'red', 'orange', 'yellow',
                  'yellowgreen', 'limegreen', 'green',
                  'cyan', 'blue', 'purple',
                  'magenta', 'pink',
                ]"
                :key="index"
                :value="color"
              ></option>
            </select>
          </span>

          <!-- Insert Hyperlink / Image / Copy -->
          <span class="ql-formats">
            <button class="ql-link"></button>
            <button class="ql-image" v-if="!disableImage"></button>
            <button
              class="ql-copy material-icons-outlined"
              @click="copyContent"
            >
              copy
            </button>
          </span>

          <!-- Export -->
          <span class="ql-formats" v-if="!disableExport">
            <button
              id="export-btn"
              class="btn btn-primary float-right custom-btn"
              v-b-modal.export-edit-legal-writer
            >
              Export
            </button>
          </span>

          <!-- Save Changes -->
          <span class="ql-formats" v-if="!disableSave">
            <button
              id="save-btn"
              class="btn btn-primary float-right custom-btn"
              @click="saveContent"
            >
              Save
            </button>
          </span>
        </span>
      </div>
      <!-- end of Toolbar -->

      <PageLoader
        :loading="editorLoading">
      </PageLoader>

      <!-- start of Content -->
      <div
        v-show="!editorLoading && htmlContent"
        ref="editor"
        id="editor-content"
      >
        <p class="content" v-html="htmlContent"></p>
      </div>
      <!-- end of Content -->
    </div>
  </div>
</template>
<script>
/* eslint-disabled */
import PageLoader from "./PageLoader.vue"
import Quill from "quill";
import ImageResize from "quill-image-resize-vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { ExportData } from "@/store/utils";

export default {
  components: {
    PageLoader,
  },

  props: {
    id: { // optional, no function yet
      type: Number,
    },
    loading: { // actually, this is not being used
      type: Boolean,
    },

    disableCard: {
      type: Boolean,
    },

    disableImage: {
      type: Boolean,
    },

    disableExport: {
      type: Boolean,
    },

    disableSave: {
      type: Boolean,
    },

    htmlContent: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      editor: null,
      editorLoading: true,
      localCopy: "",

      chosenExports: [],
      export_options: [
        { name: "PDF", icon: "picture_as_pdf" },
        { name: "DOCUMENT", icon: "description" },
      ],

      editor_color: [
        'black', 'white', 'grey',
        'red', 'orange', 'yellow',
        'yellowgreen', 'limegreen', 'green',
        'cyan', 'blue', 'purple',
        'magenta', 'pink',
      ],
    };
  },

  methods: {
    onChange() {
      this.localCopy = this.editor.root.innerHTML.replaceAll("<br>", "\n");
      this.$emit("update-editor", {
        id: this.id,
        content: this.localCopy,
      });
    },

    copyContent() {
      navigator.clipboard.writeText(
        this.editor.root.innerHTML
          .replaceAll("<br>", "\n\n")
          .replace(/(<([^>]+)>)/gi, "")
      );

      this.$toast.success("Copied");
    },

    exportContent() {
      if ( this.chosenExports.length === 0 ) {
        this.$toast.error('Kindly select a format to export');
        return;
      }

      var status = ExportData(
        this.editor.root.innerHTML,
        [
          this.chosenExports.find(el => el.name === 'DOCUMENT'),
          this.chosenExports.find(el => el.name === 'PDF'),
        ]
      );

      if (status[0]) {
        this.$toast.success("Successfully downloaded Document");
      }
      if (status[1]) {
        this.$toast.success('Successfully downloaded PDF');
      }

      this.chosenExports = [];
    },

    saveContent() {
      this.$emit('save-changes', { content: this.localCopy });
    },
  },

  created() {
    const me = this;
    setTimeout(function () {
      // register components & modules
      Quill.register(Quill.import("attributors/class/background"), true);
      Quill.register(Quill.import("attributors/class/color"), true);
      Quill.register(Quill.import("attributors/style/size"), true);
      Quill.register("modules/imageResize", ImageResize);

      // init editor
      me.editor = new Quill(`#editor-content`, {
        theme: "snow",
        placeholder: "",
        modules: {
          toolbar: `#editor-toolbar`,
          imageResize: {
            displaySize: true,
          },
        },
      });

      // update on text changes
      me.editor.on("text-change", () => {
        me.onChange();
      });

      this.localCopy = this.htmlContent;

      // pass editor to parent
      me.$emit("init-editor", {
        id: this.id,
        editor: this.editor,
        content: this.localCopy,
      });
      setTimeout(() => {
        me.onChange();
      }, 500);

      // console.log('Editor Init');

      me.editorLoading = false;
    }, 1000);

    // console.log('Editor Created');
  },
};
</script>
<style scoped>
.pr-0 {
  padding-right: 0px !important;
}
.pl-0 {
  padding-left: 0px !important;
}

.ql-container {
  height: 600px;
}
button:hover::after {
  z-index: 1000;
  position: relative;
}

.bold,
.content {
  padding: 0.5rem;
  border-radius: 10px;
}

.custom-btn {
  height: 25px !important;
  width: auto !important;
  margin: 0px;
  font-size: 12px;
  
  color: white;
  &:hover, &:focus {
    color: white;
  }
}
</style>